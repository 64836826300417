import { useContext, useMemo, useRef, useState } from 'react';

import { AppContext } from 'providers/AppProvider';
import { chatbotConfig } from 'configs/chatbotConfig';

export const useChatbot = () => {
  const intervalId = useRef();

  const currentIndex = useRef(0);
  const [generated, setGenerated] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);

  const {
    user: { isLoaded, firstname },
  } = useContext(AppContext);

  const fullConfig = useMemo(() => chatbotConfig({ firstname }), [firstname]);

  const generation = useMemo(
    () => ({
      buildConfig: () => fullConfig.slice(0, (currentIndex.current += 1)),
      base: () => {
        if (currentIndex.current - 1 === fullConfig.length) {
          generation.stop();
          return;
        }
        setGenerated(generation.buildConfig());
      },
      begin: (interval = 2000) => {
        if (isLoaded) {
          intervalId.current = setInterval(() => {
            generation.base();
          }, interval);
        }
      },
      continue: (interval) => generation.begin(interval),
      stop: () => clearInterval(intervalId.current),
    }),
    [isLoaded, fullConfig]
  );

  const addSelectedDeals = (deals) => {
    setSelectedDeals((prev) => [...prev, ...deals]);
  };

  return {
    fullConfig,
    generated,
    generation,
    selectedDeals,
    addSelectedDeals,
  };
};
