import React from 'react';
import * as S from './Button.styles';

export const Button = ({ onClick, disabled, children }) => {
  return (
    <S.Button disabled={disabled} onClick={onClick}>
      {children}
    </S.Button>
  );
};
