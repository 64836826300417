export const buttonDataLayer = (eventAction, eventLabel) => {
  window.dataLayer.push({
    event: 'button_click',
    eventAction,
    eventLabel: `/onboarding${eventLabel ?? ''}`,
  });
};

export const pageDataLayer = (pageVirtualPath) => {
  window.dataLayer.push({
    event: 'page_view',
    eventProperties: {
      page_type: 'onboarding',
      page_virtual_path: `/onboarding/${pageVirtualPath}`,
    },
  });
};
