import React, { useEffect, useState } from 'react';

import * as S from './Coupon.styles';
import { Button } from 'components/Button';
import { buttonDataLayer } from 'utils/analytics';
import { tagsToPlexure } from 'utils/_mcd-connector';
import { deals as configDeals } from 'configs/chatbotConfig';

const buttonText = 'Check je Deals';

const buildRandomDeals = (activatedDeals) => {
  if (activatedDeals.length < 4) {
    const randomDealId = Math.floor(Math.random() * 8);
    if (randomDealId > 0 && !activatedDeals.includes(randomDealId)) {
      activatedDeals.push(randomDealId);
    }
    buildRandomDeals(activatedDeals);
  }

  return activatedDeals;
};

export const Coupon = ({ selectedDeals, scrollToBottom }) => {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    let activatedDeals = [1, 2];

    if (!selectedDeals.length) {
      activatedDeals.push(5, 8);
    } else if (selectedDeals.length === 1) {
      activatedDeals.push(8);
    } else if (selectedDeals.length === 2) {
      activatedDeals.push(...selectedDeals);
    } else if (
      selectedDeals.length >= 3 &&
      selectedDeals.filter((item) => [6, 5].includes(item)).length === 2
    ) {
      for (let i = 0; i < selectedDeals.length; i++) {
        if (![6, 5].includes(selectedDeals[i])) {
          activatedDeals.push(5, selectedDeals[i]);
          break;
        }
      }
    }
    activatedDeals = buildRandomDeals(activatedDeals);

    const formattedDeal = activatedDeals.map((deal) => {
      tagsToPlexure([configDeals[deal].tag]);
      return configDeals[deal];
    });

    setDeals(formattedDeal);
  }, [selectedDeals]);

  const handleClick = () => {
    buttonDataLayer(buttonText, '/deals');
    window.open('gmalite://gmalite-deals', '_self');
  };

  useEffect(() => {
    scrollToBottom();
  }, [deals, scrollToBottom]);

  return (
    <>
      <S.Coupon>
        {deals.map(({ img }, ind) => (
          <img key={ind} src={img} alt="" draggable={false} />
        ))}
      </S.Coupon>
      <Button onClick={handleClick}>{buttonText}</Button>
    </>
  );
};
