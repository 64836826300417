import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

import { animation } from 'utils/lottie';
import * as S from './Lottie.styles';

const Lottie = ({ data, animationData, path, isStop }) => {
  const animationResult = useRef({});
  const animationContainer = useRef(null);

  useEffect(() => {
    const container = animationContainer.current;

    animationResult.current = animation({
      ...(animationData && { animationData }),
      ...(path && { path }),
      ...(data && data),
      container,
    });

    return () => lottie.destroy();
  }, [animationData, data, path]);

  useEffect(() => {
    if (typeof animationResult.current?.stop === 'function' && isStop) {
      animationResult.current.stop();
    }
  }, [isStop, animationResult]);

  return <S.AnimationContainer ref={animationContainer} />;
};

export default Lottie;
