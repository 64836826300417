import React, { useState } from 'react';
import { buttonDataLayer } from 'utils/analytics';
import * as S from './AnswerMessages.styles';

export const AnswerMessagesItem = ({
  img,
  deal,
  text,
  icon,
  onClick,
  isSubmit,
  eventLabel,
  isDisabled,
  eventAction,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  // Todo: Click handler function is meessed up
  const handleClick = () => {
    onClick(isSubmit, deal, isSelected ? -1 : 1);
    setIsSelected((prev) => !prev);
    buttonDataLayer(eventAction, eventLabel);
  };

  return (
    <S.AnswerMessagesItem
      onClick={handleClick}
      isDisabled={isDisabled}
      isSelected={isSelected}
      text={text}
    >
      {img ? <img src={img} alt="" draggable={false} /> : icon}
      <p>{text}</p>
    </S.AnswerMessagesItem>
  );
};
