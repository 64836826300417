import React, { createContext, useEffect, useState } from 'react';
import { getUserInfo } from 'utils/_mcd-connector';

export const AppContext = createContext({});

export const AppProvider = ({ children, isAppReady }) => {
  const [user, setUser] = useState({});

  const getUser = async () => {
    const data = await getUserInfo();
    setUser({ isLoaded: true, ...data });
  };

  useEffect(() => {
    if (isAppReady) getUser();
  }, [isAppReady]);

  const value = {
    user,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
