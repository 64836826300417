import styled from 'styled-components';

export const AnimationContainer = styled.div`
  position: relative;
  display: block;

  height: auto;
  width: 100%;

  padding: 0;
  margin: 0;
`;
