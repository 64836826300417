import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BurgerEmoji } from '../assets/answersEmoji/burger.svg';
import { ReactComponent as CupEmoji } from '../assets/answersEmoji/cup.svg';
import { ReactComponent as DollarEmoji } from '../assets/answersEmoji/dollar.svg';
import { ReactComponent as InfoEmoji } from '../assets/answersEmoji/info.svg';
import { ReactComponent as GamesEmoji } from '../assets/answersEmoji/games.svg';
import { ReactComponent as LickingLipsEmoji } from '../assets/answersEmoji/lickingLips.svg';
import { ReactComponent as FreeEmoji } from '../assets/answersEmoji/free.svg';
import { ReactComponent as HandsEmoji } from '../assets/answersEmoji/hands.svg';
import { ReactComponent as LoveEmoji } from '../assets/answersEmoji/love.svg';
import { ReactComponent as FrameEmoji } from '../assets/answersEmoji/frame.svg';
import { ReactComponent as ManEmoji } from '../assets/answersEmoji/man.svg';
import { ReactComponent as BagEmoji } from '../assets/answersEmoji/bag.svg';
import { ReactComponent as FlirtingEmoji } from '../assets/answersEmoji/flirting.svg';
import { ReactComponent as OfficeEmoji } from '../assets/answersEmoji/office.svg';
import { ReactComponent as CoolEmoji } from '../assets/answersEmoji/cool.svg';
import { ReactComponent as Mountain } from '../assets/answersEmoji/mountain.svg';
import { ReactComponent as Night } from '../assets/answersEmoji/night.svg';
import { ReactComponent as Ocean } from '../assets/answersEmoji/ocean.svg';
import { ReactComponent as Dawn } from '../assets/answersEmoji/dawn.svg';
import { ReactComponent as MiddagSnack } from '../assets/answersEmoji/middagSnack.svg';
import { ReactComponent as Bicycle } from '../assets/answersEmoji/bicycle.svg';
import { ReactComponent as Bell } from '../assets/answersEmoji/bell.svg';
import { ReactComponent as Car } from '../assets/answersEmoji/car.svg';
import { ReactComponent as Pc } from '../assets/answersEmoji/pc.svg';

import { ReactComponent as Apple } from '../assets/textEmoji/apple.svg';
import { ReactComponent as Emoji } from '../assets/textEmoji/emoji.svg';
import { ReactComponent as Egg } from '../assets/textEmoji/egg.svg';
import { ReactComponent as Eye } from '../assets/textEmoji/eye.svg';

import BigMac from 'assets/answersFood/bigMac.png';
import Free from 'assets/answersFood/free.png';
import Hamburger from 'assets/answersFood/hamburger.png';
import McBox from 'assets/answersFood/mcBox.png';
import FiletOFish from 'assets/answersFood/filetOFish.png';
import McChicken from 'assets/answersFood/mcChicken.png';
import McChickenSpicy from 'assets/answersFood/mcChickenSpicy.png';
import McFlurry from 'assets/answersFood/mcFlurry.png';
import McKroket from 'assets/answersFood/mcKroket.png';
import MilkShake from 'assets/answersFood/milkShake.png';
import Nuggets from 'assets/answersFood/nuggets.png';
import VeggieMcChicken from 'assets/answersFood/veggieMcChicken.png';

import Product_1 from 'assets/products/product_1.png';
import Product_2 from 'assets/products/product_2.png';
import Product_3 from 'assets/products/product_3.png';
import Product_4 from 'assets/products/product_4.png';
import Product_5 from 'assets/products/product_5.png';
import Product_6 from 'assets/products/product_6.png';
import Product_7 from 'assets/products/product_7.png';
import Product_8 from 'assets/products/product_8.png';

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  > svg {
    width: 15px;
    height: 15px;
    margin-left: 2px;
  }
`;

export const deals = {
  1: {
    tag: 'McDNL_MDB_20',
    img: Product_1,
  },
  2: {
    tag: 'McDNL_MDB_21',
    img: Product_2,
  },
  3: {
    tag: 'McDNL_MDB_22',
    img: Product_3,
  },
  4: {
    tag: 'McDNL_MDB_23',
    img: Product_4,
  },
  5: {
    tag: 'McDNL_MDB_24',
    img: Product_5,
  },
  6: {
    tag: 'McDNL_MDB_25',
    img: Product_6,
  },
  7: {
    tag: 'McDNL_MDB_26',
    img: Product_7,
  },
  8: {
    tag: 'McDNL_MDB_27',
    img: Product_8,
  },
};

export const chatbotConfig = ({ firstname }) => [
  { id: 1, text: `Hoi ${firstname}!` },
  {
    id: 2,
    render: (
      <>
        We zijn benieuwd naar een aantal dingen zodat we de MyMcDonald’s App nóg
        meer van jou kunnen maken. Het zijn 6 meerkeuze vragen. Appeltje eitje!
        <Container>
          <Apple />
          <Egg />
        </Container>
      </>
    ),
  },
  {
    id: 3,
    text: '3… 2…. 1… START! 🏁',
  },
  {
    id: 4,
    text: 'Voor welke Good Times heb jij de McDonald’s App gedownload?',
  },
  {
    id: 5,
    pageVirtualPath: '/download',
    answers: [
      [
        {
          id: 1,
          icon: <DollarEmoji />,
          text: 'Deals',
          eventAction: 'Deals',
          eventLabel: '/download/Deals',
        },
      ],
      [
        {
          id: 1,
          icon: <BurgerEmoji />,
          text: 'Sparen',
          eventAction: 'Sparen',
          eventLabel: '/download/sparen',
        },
        {
          id: 2,
          icon: <CupEmoji />,
          text: 'Winacties',
          eventAction: 'Winacties',
          eventLabel: '/download/winacties',
        },
      ],
      [
        {
          id: 1,
          icon: <GamesEmoji />,
          text: 'Games',
          eventAction: 'Games',
          eventLabel: '/download/games',
        },
        {
          id: 2,
          icon: <InfoEmoji />,
          text: 'Informatie',
          eventAction: 'Informatie',
          eventLabel: '/download/informatie',
        },
      ],
    ],
  },
  {
    id: 6,
    render: (
      <>
        Nice, hier kunnen we je geen ongelijk in geven.
        <Container>
          <Emoji />
        </Container>
      </>
    ),
  },
  {
    id: 7,
    render: (
      <>
        Vertel eens: als jij zou mogen kiezen, waar ga jij dan voor? Kies er in
        ieder geval 1. Meer mag ook.
        <Container>
          <Eye />
        </Container>
      </>
    ),
  },
  {
    id: 8,
    isMultiSelect: true,
    pageVirtualPath: '/products',
    answers: [
      [
        {
          id: 1,
          img: BigMac,
          eventAction: 'BigMac',
          eventLabel: '/products/BigMac',
        },
        {
          id: 2,
          img: Free,
          deal: 8,
          eventAction: 'Free',
          eventLabel: '/products/Free',
        },
      ],
      [
        {
          id: 1,
          deal: 5,
          img: McFlurry,
          eventAction: 'McFlurry',
          eventLabel: '/products/McFlurry',
        },
        {
          id: 2,
          img: McBox,
          deal: 7,
          eventAction: 'McBox',
          eventLabel: '/products/McBox',
        },
      ],
      [
        {
          id: 1,
          img: McChicken,
          eventAction: 'McChicken',
          eventLabel: '/products/McChicken',
        },
        {
          id: 2,
          img: Nuggets,
          deal: 4,
          eventAction: 'Nuggets',
          eventLabel: '/products/Nuggets',
        },
      ],
      [
        {
          id: 1,
          img: Hamburger,
          eventAction: 'Hamburger',
          eventLabel: '/products/Hamburger',
        },
        {
          id: 2,
          img: VeggieMcChicken,
          eventAction: 'Veggie McChicken',
          eventLabel: '/products/VeggieMcChicken',
        },
      ],
      [
        {
          id: 1,
          img: McKroket,
          deal: 3,
          eventAction: 'McKroket',
          eventLabel: '/products/McKroket',
        },
        {
          id: 2,
          img: MilkShake,
          deal: 6,
          eventAction: 'Milk Shake',
          eventLabel: '/products/MilkShake',
        },
      ],
      [
        {
          id: 1,
          img: FiletOFish,
          eventAction: 'Filet-o-Fish',
          eventLabel: '/products/FiletOFish',
        },
        {
          id: 2,
          img: McChickenSpicy,
          eventAction: 'McChickenSpicy',
          eventLabel: '/products/McChickenSpicy',
        },
      ],
    ],
  },
  {
    id: 9,
    text: 'We horen het al: ’n échte fijnproever!',
  },
  {
    id: 10,
    text: 'En en en… hoe eet je dit dan: los of in een menu?',
  },
  {
    id: 11,
    pageVirtualPath: 'size',
    answers: [
      [
        {
          id: 1,
          icon: <LickingLipsEmoji />,
          text: 'Los product',
          eventAction: 'Los product',
          eventLabel: '/size/los',
        },
      ],
      [
        {
          id: 1,
          icon: <FreeEmoji />,
          text: 'In een menu',
          eventAction: 'In een menu',
          eventLabel: '/size/menu',
        },
      ],
    ],
  },
  {
    id: 12,
    text: 'Jij geniet op jouw manier. #omnomnom',
  },
  {
    id: 13,
    text: ' Overigens ga je sneller dan het licht: je bent al op de helft! ⚡️',
  },
  {
    id: 14,
    text: 'Met wie bezoek je ons het vaakst?',
  },
  {
    id: 15,
    pageVirtualPath: 'with',
    answers: [
      [
        {
          id: 1,
          icon: <HandsEmoji />,
          text: 'Vrienden',
          eventAction: 'Vrienden',
          eventLabel: '/chatbot/with/vrienden',
        },
        {
          id: 2,
          icon: <ManEmoji />,
          text: 'Bestie',
          eventAction: 'Bestie',
          eventLabel: '/chatbot/with/bestie',
        },
      ],
      [
        {
          id: 1,
          icon: <LoveEmoji />,
          text: 'Grote liefde',
          eventAction: 'Grote liefde',
          eventLabel: '/chatbot/with/groteliefde',
        },
        {
          id: 2,
          icon: <BagEmoji />,
          text: 'Collega’s',
          eventAction: 'Collegas',
          eventLabel: '/chatbot/with/collegas',
        },
      ],
      [
        {
          id: 1,
          icon: <FrameEmoji />,
          text: 'Familie',
          eventAction: 'Frame',
          eventLabel: '/chatbot/with/frame',
        },
        {
          id: 2,
          icon: <FlirtingEmoji />,
          text: 'Alleen',
          eventAction: 'Alleen',
          eventLabel: '/chatbot/with/alleen',
        },
      ],
    ],
  },
  {
    id: 16,
    text: 'Die momenten zijn GOUD waard!',
  },
  {
    id: 17,
    text: 'Wanneer geniet je het liefst van McDonald’s?',
  },
  {
    id: 18,
    pageVirtualPath: 'when',
    answers: [
      [
        {
          id: 1,
          icon: <OfficeEmoji />,
          text: 'Doordeweeks',
          eventAction: 'Doordeweeks',
          eventLabel: '/when/doordeweeks',
        },
      ],
      [
        {
          id: 1,
          icon: <CoolEmoji />,
          text: 'Weekend',
          eventAction: 'Weekend',
          eventLabel: '/when/weekend',
        },
      ],
    ],
  },
  {
    id: 19,
    text: 'Groot gelijk heb je.',
  },
  {
    id: 20,
    text: 'En… op welk moment van de dag?',
  },
  {
    id: 21,
    pageVirtualPath: 'daypart',
    answers: [
      [
        {
          id: 1,
          icon: <Ocean />,
          text: 'Ontbijt',
          eventAction: 'Ontbijt',
          eventLabel: '/daypart/ontbijt',
        },
      ],
      [
        {
          id: 1,
          icon: <Mountain />,
          text: 'Middag',
          eventAction: 'Middag',
          eventLabel: '/daypart/middag',
        },
        {
          id: 2,
          icon: <Dawn />,
          text: 'Avondeten',
          eventAction: 'Avondeten',
          eventLabel: '/daypart/avondeten',
        },
      ],
      [
        {
          id: 1,
          icon: <MiddagSnack />,
          text: 'Middag snack',
          eventAction: 'MiddagSnack',
          eventLabel: '/daypart/middagSnack',
        },
        {
          id: 2,
          icon: <Night />,
          text: 'After dinner snack',
          eventAction: 'After dinner snack',
          eventLabel: '/daypart/afterdinnerSnack',
        },
      ],
    ],
  },
  {
    id: 22,
    text: 'Ach wat maak het eigenlijk ook uit. Good Times kent geen tijd.',
  },
  {
    id: 23,
    text: 'Tot slot: hoe bestel jij je eten het liefst?',
  },
  {
    id: 24,
    pageVirtualPath: 'POS',
    answers: [
      [
        {
          id: 1,
          icon: <Bell />,
          text: 'Balie in het restaurant',
          eventAction: 'Front counter',
          eventLabel: '/POS/frontcounter',
        },
      ],
      [
        {
          id: 1,
          icon: <Pc />,
          text: 'Bestelpaal in restaurant',
          eventAction: 'Kiosk',
          eventLabel: '/POS/kiosk',
        },
      ],
      [
        {
          id: 2,
          icon: <Car />,
          text: 'McDrive',
          eventAction: 'McDrive',
          eventLabel: '/POS/mcdrive',
        },
        {
          id: 1,
          icon: <Bicycle />,
          text: 'McDelivery (bezorgen)',
          eventAction: 'McDelivery',
          eventLabel: '/POS/mcdelivery',
        },
      ],
    ],
  },
  {
    id: 25,
    text: 'Je hebt het einde gehaald! #score. Zo kennen we je een stukje beter!',
  },
  {
    id: 26,
    text: 'Kom meteen genieten, want we hebben een paar extra Deals voor je klaargezet!',
  },
  {
    id: 27,
    coupon: true,
  },
];
