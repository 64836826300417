import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';

import LottieTyping from 'assets/animations/lottieTyping.json';
import { AnimatedMessage } from 'components/AnimatedMessage';
import { AnswerMessagesItem } from './AnswerMessagesItem';
import { pageDataLayer } from 'utils/analytics';
import Lottie from 'components/Lottie/Lottie';
import * as S from './AnswerMessages.styles';
import { Button } from 'components/Button';

export const AnswerMessages = ({
  answers,
  onClick,
  generation,
  isDisabled,
  isMultiSelect,
  scrollToBottom,
  pageVirtualPath,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const isTyping = isClicked && !isDisabled;

  useEffect(() => {
    generation.stop();
  }, [generation]);

  useEffect(() => {
    scrollToBottom();
  }, [isTyping, selectedCount, scrollToBottom]);

  useEffect(() => {
    pageDataLayer(pageVirtualPath);
  }, [pageVirtualPath]);

  const settings = {
    speed: 500,
    dots: true,
    arrows: false,
    infinite: false,
    initialSlide: 0,
    slidesToScroll: 3,
    appendDots: (dots) => <ul>{dots}</ul>,
    slidesToShow: answers.length < 3 ? answers.length : 3,
    customPaging: () => <S.Dots className="dot-item" />,
  };

  // Todo: Click handler function is meessed up
  const handleClick = (isSubmit, deal, direction) => {
    const allowSubmit = !isMultiSelect || isSubmit;
    if (allowSubmit) {
      setIsClicked(true);
      generation.continue();
    }
    setSelectedCount((prev) => prev + direction);
    onClick(deal, direction, allowSubmit);
  };

  return (
    <>
      <AnimatedMessage>
        <S.AnswersWrapper>
          <Slider {...settings}>
            {answers.map((item, ind) => (
              <div key={ind}>
                {item.map(({ id, ...rest }) => (
                  <AnswerMessagesItem
                    key={id}
                    onClick={handleClick}
                    isDisabled={isDisabled || isClicked}
                    {...rest}
                  />
                ))}
              </div>
            ))}
          </Slider>
        </S.AnswersWrapper>
        {isMultiSelect && (!!selectedCount || isClicked) && (
          <S.ButtonWrapper>
            <Button
              onClick={() => handleClick(true)}
              disabled={isDisabled || isClicked}
            >
              Dit kies ik.
            </Button>
          </S.ButtonWrapper>
        )}
      </AnimatedMessage>
      {isTyping && (
        <S.Typing>
          <Lottie animationData={LottieTyping} />
        </S.Typing>
      )}
    </>
  );
};
