import styled from 'styled-components';

export const AppWrapper = styled.div`
  background: linear-gradient(
    167.96deg,
    #910063 0%,
    #990059 4%,
    #b60035 17%,
    #ca001c 30%,
    #d7000c 42%,
    #db0007 51%,
    #e8720a 100%
  );

  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  height: 95vh;
  width: 100%;
  background: #febc0e;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border-radius: 40px 40px 0px 0px;
  max-width: 430px;
`;

export const Gradient = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 136px;
  position: absolute;
  transform: translateX(360deg);
  background: ${({ isContentScrollable }) =>
    isContentScrollable
      ? 'linear-gradient(180deg, #febc0e 50%, rgba(255, 185, 0, 0) 100%)'
      : 'transparent'};
`;
