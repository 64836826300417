import styled from 'styled-components';

export const AnswersWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  > div {
    width: 338px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  .slick-active {
    .dot-item {
      background-color: #ffbc0d;
    }
  }

  .slick-dots {
    position: static;
  }

  .slick-slide img {
    overflow: hidden;
  }
`;

export const Dots = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  box-shadow: -3px 4px 0px #000000;
  background-color: white;
  border-radius: 50%;
`;

export const Typing = styled.div`
  width: 125px;
  height: 32px;
  margin-bottom: 16px;
  transform: translateX(-15px);
`;

export const AnswerMessagesItem = styled.div`
  width: ${({ text }) => (text ? '80px' : '100px')};
  height: ${({ text }) => (text ? '80px' : '100px')};
  box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 12px 12px 0px 12px;
  box-shadow: -3px 4px 0px #000000;
  display: flex;
  flex-direction: ${({ text }) => (text ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  background-color: ${({ isSelected }) => (isSelected ? '#FEBC0E' : 'white')};
  margin-left: 12px;

  > p {
    text-align: center;
    font-family: 'Speedee', sans-serif;
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }
  > svg {
    margin-bottom: 3px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  > button {
    margin: 0;
  }
`;
