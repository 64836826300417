import styled from 'styled-components';

export const Coupon = styled.div`
  border: 2px solid black;
  box-shadow: -3px 4px 0px #000000;
  border-radius: 16px 16px 16px 0px;
  display: flex;
  width: 240px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 10px 0;

  > img {
    width: 103px;
    height: 109px;
  }
`;
