import React, { useEffect, useRef, useCallback } from 'react';

import { Coupon } from 'components/Coupon';
import Lottie from 'components/Lottie/Lottie';
import { useChatbot } from 'hooks/useChatbot';
import { TextMessage } from 'components/TextMessage';
import { AnswerMessages } from 'components/AnswerMessages';
import { Typing } from '../AnswerMessages/AnswerMessages.styles';
import LottieTyping from 'assets/animations/lottieTyping.json';

export const ChatBot = ({ content, setIsContentScrollable }) => {
  const { fullConfig, generation, generated, selectedDeals, addSelectedDeals } =
    useChatbot();

  const scrollToBottom = useCallback(() => {
    if (content.current) {
      content.current.scrollTop = content.current.scrollHeight;
    }
  }, [content]);

  const product = useRef({});

  useEffect(() => {
    generation.begin();
    return () => generation.stop();
  }, [generation]);

  useEffect(() => {
    if (!!fullConfig[generated.length]?.answers) {
      generation.stop();
      generation.continue(1000);
    }
  }, [generated, generation, fullConfig]);

  // Todo: refactor and prettify
  // Todo: Click handler function is messed up
  const handleAnswerClick = (deal, direction, allowSubmit) => {
    if (deal) {
      if (!product.current[deal]) {
        product.current[deal] = 1;
      } else {
        product.current[deal] += direction;
        if (!product.current[deal]) delete product.current[deal];
      }
    }
    if (allowSubmit) {
      addSelectedDeals(Object.keys(product.current).map((item) => +item));
      product.current = {};
    }
  };

  useEffect(() => {
    if (content.current) {
      setIsContentScrollable(
        content.current.scrollHeight - content.current.offsetHeight > 0
      );
    }
  }, [content, generated, setIsContentScrollable]);

  return (
    <>
      {!generated.length && (
        <Typing>
          <Lottie animationData={LottieTyping} />
        </Typing>
      )}
      {generated?.map(
        (
          { id, answers, text, coupon, pageVirtualPath, isMultiSelect, render },
          ind
        ) => {
          switch (true) {
            case !!coupon:
              return (
                <Coupon
                  key={id}
                  selectedDeals={selectedDeals}
                  scrollToBottom={scrollToBottom}
                />
              );
            case !!answers:
              return (
                <AnswerMessages
                  key={id}
                  answers={answers}
                  generation={generation}
                  onClick={handleAnswerClick}
                  isMultiSelect={isMultiSelect}
                  selectedDeals={selectedDeals}
                  scrollToBottom={scrollToBottom}
                  pageVirtualPath={pageVirtualPath}
                  addSelectedDeals={addSelectedDeals}
                  isDisabled={ind !== generated.length - 1}
                />
              );
            case !!text || !!render:
              return (
                <TextMessage
                  key={id}
                  text={text}
                  scrollToBottom={scrollToBottom}
                  render={render}
                />
              );
            default:
              return null;
          }
        }
      )}
    </>
  );
};
