import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedMessage = styled.div`
  animation: ${animation} 0.2s ease;
`;
