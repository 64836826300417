import styled from 'styled-components';

export const ChatBot = styled.div`
  height: 100%;
  display: flex;
  overflow-y: auto;
  scroll-behavior: smooth;
  flex-direction: column;
  margin-top: 64px;
  padding: 0 20px;
`;
