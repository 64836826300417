import React, { useState, useEffect, useRef } from 'react';
import * as S from './TextMessage.styles';
import { AnimatedMessage } from 'components/AnimatedMessage';

export const TextMessage = ({ text, render, scrollToBottom }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    const width = ref.current?.getBoundingClientRect().width;
    setWidth(width);
  }, [ref]);

  return (
    <AnimatedMessage>
      <S.Message style={{ width }}>
        <span ref={ref}>{text || render}</span>
      </S.Message>
    </AnimatedMessage>
  );
};
