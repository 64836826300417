import React, { useRef, useState } from 'react';

import { Layout } from './layouts';
import GlobalStyles from './globalStyles';
import { ChatBot } from 'components/ChatBot';
import * as S from 'components/ChatBot/ChatBot.styles';

export const App = () => {
  const content = useRef();
  const [isContentScrollable, setIsContentScrollable] = useState(false);

  return (
    <>
      <GlobalStyles />
      <Layout isContentScrollable={isContentScrollable}>
        <S.ChatBot ref={content}>
          <ChatBot
            content={content}
            setIsContentScrollable={setIsContentScrollable}
          />
        </S.ChatBot>
      </Layout>
    </>
  );
};
