import styled from 'styled-components';

export const Message = styled.div`
  max-width: 80%;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  word-wrap: break-word;
  width: max-content;
  background-color: white;
  font-family: 'Speedee', sans-serif;
  border: 2px solid #000000;
  box-shadow: -3px 4px 0px #000000;
  border-radius: 16px 16px 16px 0px;
  margin: 0 0 16px 0;
`;
