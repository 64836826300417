import React, { useEffect, useState } from 'react';

import * as S from './Layout.styles';
import { Close } from 'components/Close';
import { AppProvider } from 'providers/AppProvider';
import {
  isDev,
  isGmt,
  isMcdDefined,
  toggleFullscreen,
  orientationScreen,
} from 'utils/_mcd-connector';

const onMCDBridgeReady = () => {
  orientationScreen();
  toggleFullscreen();
};

export const Layout = ({ children, isContentScrollable }) => {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    if (isDev || isGmt) return setIsAppReady(true);
    const listenerId = document.addEventListener('mcdBridgeReady', () => {
      onMCDBridgeReady();
      setIsAppReady(true);
    });

    if (isMcdDefined && !isDev && !isGmt) {
      setIsAppReady(true);
    }

    return () => document.removeEventListener(listenerId);
  }, []);

  return (
    <AppProvider isAppReady={isAppReady}>
      <S.AppWrapper>
        <S.ContentWrapper>
          {isAppReady && children}
          <S.Gradient isContentScrollable={isContentScrollable}>
            <Close />
          </S.Gradient>
        </S.ContentWrapper>
      </S.AppWrapper>
    </AppProvider>
  );
};
