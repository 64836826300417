import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import * as S from './Close.styles';

export const Close = () => {
  const handleClose = () => {
    window.open('gmalite://gmalite-home', '_self');
  };

  return (
    <S.WrapperClose>
      <CloseIcon onClick={handleClose} />
    </S.WrapperClose>
  );
};
