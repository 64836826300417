import styled from 'styled-components';

export const WrapperClose = styled.div`
  display: flex;
  justify-content: flex-end;

  > svg {
    position: relative;
    top: 17px;
    right: 19px;
    box-shadow: -3px 4px 0px #000000;
    border-radius: 50%;
    z-index: 1;
  }
`;
