import styled from 'styled-components';

export const Button = styled.button`
  background: linear-gradient(
    128.45deg,
    #910063 35.75%,
    #990059 37.92%,
    #b60035 45%,
    #ca001c 52.09%,
    #d7000c 58.62%,
    #db0007 63.52%,
    #e8720a 90.21%
  );
  font-family: 'Speedee', sans-serif;
  margin-top: 16px;
  margin-bottom: 170px;
  border: 2px solid black;
  box-shadow: -3px 4px 0px #000000;
  font-weight: 700;
  width: max-content;
  font-size: 16px;
  padding: 16px;
  color: white;
  line-height: 20px;
  border-radius: 12px;
`;
